import * as wjGrid from '@mescius/wijmo.grid';
import { AutoComplete } from '@mescius/wijmo.input';
import store from '@/store';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  ORGANIZATIONAL_DIVISION
}
  from '@/constants/registerData'
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate'
import debounce from 'lodash/debounce'
import { validateNumberFieldBySetting, math, formatBigNumber, formatEmission25Number, formatNumberBySetting  } from './wijmo.helper'

import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getBranchId } from '@/concerns/utils/filter-data'
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { getWidthOfAttachedButton } from '@/utils/multiLanguage';
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    activity: null,
    source: null,
    scale_index_source: null,
    scale_index_unit: null,
    scale_index_value: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
  }
}

let notNullList = [
  'organizational_division',
  'company_name',
  'scale_index_source',
  'emissions',
]

const max25Charactor = [
]

const max128Charactor = [
  // 'company_name',
  // 'business_name',
  'scale_index_source',
  'source',
  'memo_1',
  'memo_2'
]

const numberField = [
  "emissions"
];

let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let filterIndex = {};
const partern = {
  initializeGrid(dataProps) {
    const { flexgrid, itemCount, branchData = {} } =  dataProps
    let selectedFirst = null;
    let previousCellData = null;
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

      
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          const binding = s.columns[colIndex].binding;
          if (filterColumns.includes(binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex)
            }
          }
        }
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const binding = column.binding

      if (filterColumns.includes(binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let item = s.rows[e.row].dataItem;
      let column = s.columns[e.col];
      // auto ++ serial number
      if (!item.index) {
        item.index = flexgrid?.rows?.length - 1 || 1;
      }
      if (
        column.binding === 'scale_index_unit' ||
        column.binding === 'wsu_value' ||
        column.binding === 'activity' ||
        column.binding === 'wsu_unit'
      ) {
        e.cancel = true;
      }

      if (filterColumns.includes(column.binding)) {
        const { row, col } = e.range
        const cellData = s.getCellData(row, col, false)
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
      }
    });
    
  },

  header(dataProps) {
    const { 
      registerData, 
      listLayout, 
      branchData, 
      attachFilePopup
    } = dataProps

    let companyMap = new wjGrid.DataMap(branchData.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organizational_division'),
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 140,
        maxWidth: 400,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_company_name'),
        binding: 'company_name',
        minWidth: 140,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: branchData?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: i18n.t('56_pattern.table_scale_index_source_c14'),
        binding: 'scale_index_source',
        minWidth: 154,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        cssClassAll: 'required-field',
      },
      {
        header: i18n.t('56_pattern.table_source'),
        binding: 'source',
        minWidth: 83,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true,
      },
      {
        header: i18n.t('56_pattern.table_memo_1'),
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_memo_2'),
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_emissions_c14'), // scale_index_value × wsu_value
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission required-field',
        align: 'right',
        minWidth: 200,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
      },
      {
        header      : i18n.t('56_pattern.table_attach_file'),
        binding     : "attach_file",
        cssClass: 'auto-increment',
        minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired  : false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        } 
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },

  handlerDataOnTable(dataProps) {
    const {
      dataTable,
      bindingLayout,
      isSaveTemplate,
      branchData,
      isGetBranchDetail,
      dbStore,
      itemAdded
    } = dataProps
    let databaseTypeObject = {};

    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};
    if(!isGetBranchDetail) return
   // formatValue(emissions)?.substring(0, 20) = formatValue(emissions)?.substring(0, 20);

    if (typeof dataTable.evidence === 'string') { 
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(formatNumberBySetting(dataTable.emissions)) || null,
      scale_index_source: dataTable.scale_index_source?.trim() || null,
      source: dataTable.source || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      type:  null,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
    };
      
    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    
    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'lease_asset_name',
    'scale_index_source',
    'scale_index_unit',
    'wsu_unit',
    'wsu_source',
  ],
  getError(item, propName, dbStore, validateErrorBtn = {}) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;
    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (item[propName] === null || item[propName] === undefined) {
        return null;
      }
      return validateNumberFieldBySetting(String(item[propName]));
    }

    if (max25Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return i18n.t('new_validate.error_mapping_layer', { listLayer: itemsInViewError.map(item => item)?.join('/') })
      }
    }
    return null
  },
  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'scale_index_source',
      'source',
      'memo_1',
      'memo_2',
      'attach_file',
      'emissions',
      ...bindingLayout
    ];
  },
  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'scale_index_source',
      ...bindingLayout
    ];
  },
  autoFields: [],
  patternUrl: '/pattern-s3-c14/',

  calcEmissions(rowData) {
    return rowData?.emissions
  },

  bindingValueColumn: 'emissions',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  },
  columnsCalcEmission: ['emissions'],
  getAutoBindingDbSource(){}
};

export default partern;
